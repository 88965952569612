.wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #0f0f0f6a;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  background-color: #fff;
	padding: 10px;
  height: 500px;
  width: 40vw;
  max-width: 500px;
  min-width: 300px;
	border-radius: 10px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	position: relative;
	/* gap: 10px; */
}

.inputs {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	gap: 10px;
}
.close {
	position: absolute;
	top: 10px;
	right: 10px;
	font-size: 40px;
	color: #474747;
	z-index: 2131231231;
}

.textarea{
	width: 97%;
	height: 245px;
  	background: #f7f8fa;
  	border-radius: 10px;
	font-size: 16px;
  	border: none;
  	padding: 22px;
	box-sizing: border-box;
}