* {
  margin: 0;
  padding: 0;
}
.personalDetails {
  width: 98%;
  max-width: 1238px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}
.div_input {
  width: 48%;
  min-width: 355px;
  max-width: 610px;
  background-color: #ffff;
  height: auto;
  max-height: 650px;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 30px 30px;
  display: flex;
	align-items: center;
  flex-wrap: wrap;
	flex-direction: column;
	gap: 5px;
}
.admins__input {
	height: 420px;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	gap: 20px;
}
.div_input > input {
	height: 60px;
}
.div_input  button {
	height: 50px;
}

.div_input > span{
  display: block;
  width: 97%;
  text-align: start;
  font-size: 12px;
  color: red;
  padding-left: 10px;
}

.div__person__input {
	width: 48%;
  min-width: 355px;
  max-width: 610px;
  /* max-width: 610px; */
  background-color: #ffff;
  height: 550px;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 30px 30px;
}

.div__person__input > button {
  width: 197px;
  height: 65px;
}

h3 {
  font-size: 18px;
}

.card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;
  color: #b4b4b4;
}
.card p {
	min-width: 100px;
}

.card > svg {
  cursor: pointer;
  width: 40px;
}

.items {
	margin-top: 20px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 20px;
	min-height: 355px;
}

.div_input > button {
  height: 65px;
}


.input_email:read-only{
  cursor: pointer;
}

@media (max-width: 1080px){
  .div_input, .div__person__input{
    width: 90vw;
  }


}