
.wrapper {
	width: 80vw;
  height: auto;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

.register {
	margin: 50px 0;
	border-radius: 20px;
  width: 90vw;
  max-width: 600px;
	display: flex;
	justify-content: center;
	padding: 0 40px;
	flex-wrap: wrap;
  height: auto;
	min-height: 900px;
	background-color: #fff;
	box-shadow: 0 0 20px #d3d3d3;
}

.register h1 {
	margin-top: 50px;
  text-align: center;
  font-size: 50px;
}

.input__container > span{
  display: block;
  width: 97%;
  text-align: start;
  font-size: 14px;
  color: red;
  padding-left: 10px;
}

@media (max-width: 600px) {
	.register  h1 {
    font-size: 36px;
  }
}

@media (min-width: 601px) and (max-width: 800px) {
	.register   h1 {
    font-size: 40px;
  }
}
@media (min-width: 801px) and (max-width: 1150px) {
	.register  h1 {
    font-size: 45px;
  }
}

.register > button {
  width: 97%;
}

.custom-input {
  width: 18px;
}

.radio {
  display: flex;
  align-items: center;
  gap: 8px;
}

a {
  text-decoration: none;
  color: #ff6e1d;
}

.custom-label {
  font-size: 16px;
  font-weight: 400;
  color: #b4b4b4;
}

.author {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 94%;
  height: 30px;
  background: #ff6e1d;
  border-radius: 10px;
  border: none;
  padding: 10px;
  text-align: center;
  color: white;
}

.register * {
  margin-top: 10px;
}

.error {
  color: red;
}

@media (max-width: 1000px) {
	.wrapper{
		width: 100vw;
	}
}