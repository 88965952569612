.container {
  position: relative;
}

.rdrInfiniteMonths {
  scrollbar-color: #e6e7e9;
  scrollbar-width: thin;
}

.rdrMonthAndYearPickers {
  font-size: 16px;
}

.rdrMonthName {
  font-size: 16px;
  color: #000;
}

.calendar-container,
.calendar-container-payment {
  height: 666px;
  position: absolute;
  width: 368px;
  display: flex;
  left: 200px;
}
.calendar-container-payment {
  left: -155px;
  top: 30px;
}
.scrollable-calendar {
  position: absolute;
  height: 90%;
  max-height: 666px;
  overflow-y: hidden;
  width: 100%;
}
.header {
  display: flex;
  width: 349px;
  justify-content: space-between;
  background-color: #ffff;
  padding-top: 16px;
}

.header > p {
  font-weight: 600;
  font-size: 28px;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: #000;
}

.close_calendar {
  position: absolute;
  top: 500px;
  background-color: #ff6e1d;
  color: white;
  border-radius: 10px;
  width: 328px;
  height: 65px;
  left: 10px;
  border: none;
}

@media (max-width: 1000px) {
  .scrollable-calendar {
    left: -200px;
    position: absolute;
    height: 100%;
    overflow-y: scroll;
  }

  .close_calendar{
    left: -195px;
    height: 40px;
  }

  .calendar-container-payment {
    left: 170px;
  }
}
.open {
  color: #ffa978;
  font-size: 16px;
  cursor: pointer;
  display: flex;
}
