.wrapper {
	margin-top: 20px;
  display: flex;
  justify-content: center;
	align-items: center;
	flex-wrap: wrap;
  width: 70vw;
}
@media (max-width: 1000px) {
	.wrapper{
		width: 100%;
	}
}