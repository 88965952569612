.header {
	box-sizing: border-box;
	padding: 20px;
	width: 100%;
	max-width: 1240px;
	font-size: 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.header  img {
	height: 50px;
  width: 50px;
}

.header > div {
	cursor: pointer;
}

.header svg{
	font-size: 50px;
	max-width: 50px;
	height: 50px;
  width: 50px;
}

svg {
	max-width: 50px;
}

@media (max-width: 1100px) {
	.header{
		width: 100%;
	}
}