.wrong-back {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

body {
  margin: 0;
}

.wrong {
  width: 90vw;
  max-width: 500px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  background-color: #f7f8fa; 
  border-radius: 10px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.11);
}

/* @media (max-width: 600px) {
	.wrong{
		width: 90vw;
	}
}

@media (min-width: 601px) and (max-width: 800px) {
	.wrong{
		width: 50vw;
	}
}
@media (min-width: 801px) and (max-width: 1150px) {
	.wrong{
		width: 40vw;
	}
} */

.wrong p {
  font-size: 20px;
  font-weight: bold;
}

.wrong button {
	margin-top: 20px;
	width: 100vw;
  max-width: 130px;
  height: 46px;
}
