.wrapperPayment{
	box-sizing: border-box;
	padding: 23px 32px;
	width: 98%;
	max-width: 1546px;
	border-radius: 10px;
	background: #fff;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.14);
	display: flex;
	justify-content: space-between;
	  gap: 20px;
	  flex-wrap: wrap;
	  /* height: 129px;  */
  }
  
  .item {
	  display: flex;
	  align-items: center;
	  flex-wrap: wrap;
	  width: 50%;
	  max-width: 500px;
  }
  
  .payment {
	  min-height: 50px;
	  display: flex;
	  justify-content: space-between;
	  /* flex-wrap: wrap; */
	  gap: 10px;
	  font-size: 16px; 
	  width: 100%;
  }
  
  .centerCalendar {
	display: flex;
	position: relative;
	flex-direction: column;
  }
  
  .range {
	display: flex;
	color: #ff6e1d;
	align-items: flex-start;
	font-size: 14px;
	font-weight: 600;
  }
  
  .range > P {
	font-size: 14px;
	font-weight: 600;
	padding-top: 3px;
  }

  @media (max-width: 1000px){
	.payment > p{
		font-size: 16px;
	}
	.item{
		width: 100%;
	}
  }