* {
  margin: 0;
  padding: 0;
}
.div_navigate {
  max-width: 310px;
  width: 25%;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  background-color: #ffffff;
}

.fixed {
  position: fixed;
  z-index: 5;
}

.layout {
  box-sizing: border-box;
  padding: 0px 30px;
  width: 30%;
  min-width: 280px;
  max-width: 310px;
  position: fixed;
  height: 100vh;
  z-index: 5;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #ffffff;
  box-shadow: -4px 0px 22px 0px rgba(0, 0, 0, 0.11);
}

.navigate_logo {
  height: 120px;
  display: flex;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
  background-color: #ffffff;
}

.navigate_logo > img {
  height: 50px;
  width: 50px;
}

.navigate_logo > p {
  font-size: 25px;
  font-weight: bold;
}

.navigate_menu {
  margin-top: 40px;
  position: relative;
  /* width: 100%; */
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
}
.nav_ul {
  width: 100%;
  height: 80vh;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
}

.nav_ul > li {
  width: 100%;
}
.nav_li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

li > a {
  background-color: transparent;
  display: block;
  width: 100%;
}
.nav_li a {
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  color: black;
  text-align: start;

  svg {
    color: #b4b4b4;
    margin-right: 16px;
  }
}
.nav_li a > h2 {
  text-align: start;
}

.nav_li:hover {
  a {
    color: #ff6e1d;
    font-weight: 600;
    svg {
      color: #ff6e1d;
    }
  }
}
.nav_li a.font-link {
  color: #ff6e1d;
  font-family: "Visuelt Pro";
  svg {
    color: #ff6e1d;
  }
}

.button {
  min-width: 180px;
  width: 100%;
  font-size: 18px;
  height: 64px;
  background: var(--orange);
  border-radius: 10px;
  border: none;
  color: white;
  margin-left: -20px;
}

.button svg {
  font-size: 20px;
  margin-right: 10px;
}

.button__type {
  display: flex;
  gap: 0px;
  align-items: center;
  justify-content: center;
}

.button__type button {
  height: 105px;
}

.email__tabs {
  color: #565656;
  cursor: pointer;
  font-weight: bold;
  transition: color 0.5s;
  margin-left: 0;
  p {
    font-size: 16px;
  }
  a {
    color: #565656;
  }
}

.email__tabs:hover {
  color: #ff6e1d;
  a {
    color: #ff6e1d;
  }
}

.logout {
  color: #b4b4b4;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  justify-self: flex-end;
  position: absolute;
  bottom: 40px;
}

.logout:hover {
  color: #ff6e1d;
  font-weight: 600;
}

.close {
  position: absolute;
  top: 20px;
  left: 330px;
  cursor: pointer;
}
.close svg {
  font-size: 40px;
}

@media (max-width: 1000px) {
  .navigate_menu {
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .nav_ul {
    height: auto;
    margin-bottom: 40px;
  }
}

@media (max-width: 700px) {
  .div_navigate {
    width: 270px;
    z-index: 5;
  }
  .navigate_menu {
    justify-content: flex-start;
  }

  .logout {
    bottom: -20px;
  }
}
