.wrapper{
	color:  #858585;;
	font-size: 20px;
	display: flex;
	align-items: center;
	height: 50px;
}

.items {
	display: flex;
	gap: 5px;
}

.active {
	color: #FF6E1D
}

.wrapper > svg {
	font-size: 30px;
}

.wrapper > span {
	cursor: pointer;
}