* {
  margin: 0;
  padding: 0;
}
.personalDetails {
  width: 65vw;
  max-width: 1238px;
  height: 594px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}
.div_input {
  width: 50%;
  min-width: 355px;
  max-width: 610px;
  background-color: #ffff;
  height: 550px;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 30px 30px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.div_input > input {
	height: 60px;
}
.div_input  button {
	height: 50px;
}

.div__person__input {
	width: 50%;
  min-width: 355px;
  max-width: 610px;
  /* max-width: 610px; */
  background-color: #ffff;
  height: 550px;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 30px 30px;
}

.div__person__input > button {
  width: 197px;
}

h3 {
  font-size: 18px;
}

.card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;
  color: #b4b4b4;
}
.card p {
	width: 165px;
}

.card > svg {
  cursor: pointer;
  width: 40px;
}

.items {
	margin-top: 20px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 20px;
	min-height: 400px;
}

.div_input > button {
  height: 65px;
}

.div_input > p{
  display: block;
  width: 97%;
  text-align: start;
  font-size: 12px;
  color: red;
  padding-left: 10px;
}

.close {
	position: relative;
	z-index: 100;
	top: -500px;
	left: 49rem;
}
.close svg {
	font-size: 30px;
}

@media (min-width: 800px) and (max-width: 1200px) {
	.close {
		left: 70rem;
	}
}

@media (max-width: 800px) {
	.close {
		left: 100rem;
	}
}

@media (max-width: 1900px) {
	.close {
		top: -1072px;
    left: 435px;
	}
}