.wrapperAuthor {
  margin-top: 10px;
  color: #808080;
  width: 100%;
  display: flex;
  justify-content: space-between;
  max-width: 1500px;
  border-radius: 10px;
  background: #fff;
  padding: 17px 20px;
  transition: all 0.5s;
  cursor: pointer;
}

@media (max-width: 1000px) {
  .wrapperAuthor {
    width: 100%;
    overflow-y: auto;
    p{
      font-size: 12px;
    }
    .name{
      text-align: left;
    }
  }
}

.wrapperAuthor:hover {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.11);
  color: #000;
}

.wrapperAuthor > .first {
  width: 50%;
  min-width: 200px;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
  font-size: 20px;
  cursor: pointer;

  .email{
    margin-left: 30px;
  }
}

.first > p {
  display: block;
  text-align: left;
}

.end {
  width: 5vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.end > svg {
  font-size: 25px;
  cursor: pointer;
  transition: color 0.5s;
}

.end > svg:hover {
  color: #ff6e1d;
}

.confirm {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(255, 255, 255, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  top: 0;
  left: 0;
}

.confirm_content {
  width: 90vw;
  position: relative;
  max-width: 500px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  background-color: #f7f8fa;
  border-radius: 10px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.11);
  padding: 30px;
  text-align: center;
}

.confirm_content > P {
  font-size: 20px;
  font-weight: bold;
}

.confirm_content > svg {
  position: absolute;
  top: 20px;
  right: 20px;
}

.confirm_content > div {
  width: 90%;
  display: flex;
  gap: 24px;
  justify-content: center;
}

.confirm_button,
.confirm_button_cancel {
  margin-top: 20px;
  width: 45%;
  max-width: 130px;
  height: 46px;
  background: var(--orange);
  border-radius: 10px;
  border: none;
  color: #fff;
  font-size: 16px;
}

.confirm_button_cancel {
  background: var(--grey);
  color: #adadad;
}

@media (max-width: 1000px) {
  .confirm_content > p {
    font-size: 16px;
    font-weight: bold;
  }
}