.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow-y: scroll;
  background-color: #afafaf68;
  z-index: 123012301;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 40px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  align-items: center;
}

.blocker{
  width: 65vw;
  display: flex;
  justify-content: center;
}

.blocker > button{
  width: 50%;
  height: 65px;
  padding: 10px 20px;
  background-color: #FF6E1D;
  color: #fff;
  font-size: 18px;
  border: none;
  border-radius: 10px;
  margin-bottom: 20px;
}
