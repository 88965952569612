* {
  margin: 0;
  padding: 0;
}
.sum_main {
  margin-bottom: 10px;
  width: 98%;
	min-width: 355px; 
  max-width: 1238px;
  height: 251px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  background: #fff;
  padding: 30px 30px;
  box-sizing: border-box;
}

.header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.header > div {
  font-size: 18px;
  color: #ff6e1d;
  cursor: pointer;
}

.centerCalendar {
  display: flex;
  position: relative;
}

.range {
  display: flex;
  color: #ff6e1d;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
}

.range > P {
  font-size: 14px;
  font-weight: 600;
  padding-top: 3px;
}

p {
  font-size: 18px;
}
.sum_main h2 {
  font-size: 50px;
}
.sum_button {
  width: 131px;
  height: 65px;
  background-color: #ff6e1d;
  color: #ffffff;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 400;
  border: none;
}

.count{
  width: 90%;
  display: flex;
  align-items: center;
  margin: 10px 5px 0
}
 .count > span {
  font-size: 24px;
  font-weight: 500;
 }

 .count > p{
  font-size: 24px;
  font-weight: 700;
  margin-top: 2px;
 }

 @media (max-width: 600px) {
  .count > span{
    font-size: 16px;
  }
 }
