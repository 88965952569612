.center {
  width: 100%;
    min-width: 1240px;
  }
  
  .items {
    width: 1340px;
    overflow:hidden;
    margin-top: 10px;
    display: flex;
    gap: 15px;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    background: #b6b6b6;
    padding: 11px 20px;
    padding-left: 40px;
    color: #fff;
    font-size: 18px;
    box-sizing: border-box;
  }
  
  .name {
    width: 13%;
    min-width: 80px;
  }
  .images{
    width: 10%;
  }
  .item {
    display: flex;
    gap: 16px;
    max-height: 200px;
    width: 30%;
    justify-content: flex-start;
  }
  
  .item > div {
    width: 40%;
  }
  
  .item_two {
    display: flex;
    max-height: 200px;
    width: 43%;
    justify-content: space-between;
  }
  
  .status {
    text-align: center;
    width: 140px;
  }
  
  .item_two > div {
    width: 24%;
    text-align: center;
  }
  
  /* @media (max-width: 1200px){
      
  }
  
  @media (max-width: 1000px) {
    .center {
      width: 90vw;
    }
    .items {
      width: 90vw;
    }
    .status{
      margin-left: 10px;
  }
  }
   */