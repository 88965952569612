.register__container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 150vh;
  width: 100vw;
	background-color: #ffff;

}

body{
	background-color: #ffff;
}

.register {
  width: 90vw;
  max-width: 600px;
}

.input__container > span{
  display: block;
  width: 97%;
  text-align: start;
  font-size: 14px;
  color: red;
  padding-left: 10px;
}

h1 {
  text-align: center;
  font-size: 55px;
}

.role{
  display: flex;
  width: 97%;
  gap: 5px;
}

@media (max-width: 600px) {
  h1 {
    font-size: 36px;
  }
}

@media (min-width: 601px) and (max-width: 800px) {
  h1 {
    font-size: 40px;
  }
}
@media (min-width: 801px) and (max-width: 1150px) {
  h1 {
    font-size: 45px;
  }
}

.register > button {
  width: 97%;
}

.custom-input {
  width: 18px;
  color: #ff6e1d;
}

.radio {
  display: flex;
  align-items: center;
  gap: 8px;
}

a {
  text-decoration: none;
  color: #ff6e1d;
}

.custom-label {
  font-size: 16px;
  font-weight: 400;
  color: #b4b4b4;
}

.data-processing-link{
  font-family: var(--font-family);
font-weight: 400;
font-size: 14px;
color: #b4b4b4;
text-decoration: none;
}

.div_role {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color:#b4b4b4 ;
  border-radius: 10px;
  border: none;
  padding: 10px;
  text-align: center;
  color: white;
  font-size: 16px;
}

.active{
  background: #ff6e1d;
}

.register * {
  margin-top: 20px;
}

.error {
  width: 97%;
	height: 65px;
  background: #f7f8fa;
  border-radius: 10px;
	font-size: 16px;
  border: none;
  padding: 22px;
	box-sizing: border-box;
  border: 1px solid red;
}

.password{
  display: block;
  position: relative;
}

.password > svg{
  position: absolute;
  top: 25px;
  right: 30px;
  color: #b4b4b4;
}

.input {
  width: 97%;
	height: 65px;
  background: #f7f8fa;
  border-radius: 10px;
	font-size: 16px;
  border: none;
  padding: 22px;
	box-sizing: border-box;
}

.input:focus-visible {
  outline: 1px solid var(--orange);
}

.input[type="radio"] {
  appearance: none;
  display: inline-block;
  width: 20px;
  height: 20px;
  padding: 2px;
  background-clip: content-box;
  border: 2px solid #bbbbbb;
  background-color: #e7e6e7;
  border-radius: 50%;
}

.input[type="radio"]:checked {
  background-color: var(--orange);
}

