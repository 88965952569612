.layout {
  width: 100vw;
  display: flex;
  gap: 50px;
}

@media (max-width: 1200px) {
  .layout {
    padding: 0 10px;
  }
}
@media (max-width: 1000px) {
  .layout {
    width: 98vw;
    margin: 0 auto;
    gap: 0px;
  }
}
@media (min-width: 1001px) and (max-width: 1400px) {
  .layout {
    gap: 50px;
  }
}
