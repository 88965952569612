body {
	margin: 0;
	padding: 0;
	background-color: #f7f8fa;
  }

  .myDetails_main{
	width: 75%;
  }
  
  .div_all_right {
	  margin-top: 20px;
	height: 25vh;
	display: flex;
	  align-items: flex-start;
	flex-direction: column;
	gap: 3rem;
  }
  .div__add{
	width: 100%;
  }
  @media (max-width: 1000px) {
	  .div_all_right{
		  width: 100%;
	  }
	  .myDetails_main{
		width: 100%;
	  }
  }
  