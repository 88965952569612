body {
  margin: 0;
  padding: 0;
  background-color: #f7f8fa;
}

.wrapper {
  width: 100%;
  max-width: 1240px;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 0 16px;
}

.sorted,
.items {
  width: 98%;
}
.items {
  overflow-x: auto;
}
.header {
  margin-top: 45px;
  margin-bottom: 45px;
  width: 98%;
  max-width: 1240px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.info {
  width: 100%;
}
.data {
  width: 98%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.data > .title {
  font-size: 40px;
}

.data > .email {
  font-size: 25px;
  color: #b6b6b6;
}

.subscribers {
  display: flex;
  width: 40%;
  gap: 20px;
  margin-top: 20px;
  justify-content: flex-start;
  align-items: center;
  padding-left: 12px;
}

.subscribers > span {
  font-size: 26px;
  width: 75%;
  font-weight: 400;
}

.subscribers > p {
  font-size: 22px;
  font-weight: 700;
}

.header > input {
  background: #e6e7e9;
  max-width: 650px;
  width: 30vw;
  min-width: 400px;
}

.item > svg {
  font-size: 24px;
}

.items {
  max-height: 900px;
}

.right {
  margin-top: 10px;
  width: 100%;
  max-width: 1240px;
  display: flex;
  justify-content: flex-end;
}

.tabs {
  box-sizing: border-box;
  padding: 23px 32px;
  width: 98%;
  max-width: 1240px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.14);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  transition: width 0.5s;
  margin-bottom: 16px;
  /* height: 116px;  */
}

.tabs > .input {
  max-width: 650px;
  width: 45%;
  min-width: 400px;
  margin-bottom: 16px;
}
.tabs > .buttons {
  display: flex;
  width: 50%;
  gap: 20px;
  margin-bottom: 16px;
}
.buttons > .item {
  height: 65px;
  min-width: 190px;
  max-width: 215px;
  width: 45%;
  color: #fff;
  font-size: 30px;
  font-weight: 400;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  background: #b6b6b6;
  overflow-x: auto;
  padding: 0 40px;

  p {
    font-size: 20px;
    margin-top: 5px;
  }

  svg {
    font-size: 35px;
  }
}

.buttons > .itemActive {
  background: #ff6e1d;
}

table {
  width: 98%;
  max-width: 1240px;
  height: auto;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-spacing: 0;

  thead {
    tr {
      width: 100%;
      height: 65px;

      th {
        font-size: 16px;
        font-weight: 600;
        border-right: 1px solid rgb(202, 201, 201);
        text-align: center;
        width: auto;
        border-bottom: 1px solid rgb(202, 201, 201);

        div {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          svg {
            margin-left: 10px;
            height: 20px;
          }
        }
      }

      th:last-child,
      td:last-child {
        border-right: none;
      }
    }
  }

  tbody {
    tr {
      height: 65px;

      td {
        font-size: 16px;
        color: rgb(114, 113, 113);
        font-weight: 400;
        text-align: end;
        padding-right: 10px;

        svg {
          font-size: 12px;
          width: 14px;
        }
      }

      td:last-child {
        width: 140px;
        svg {
          font-size: 24px;
          width: 25px;
        }
      }

      .pending {
        color: rgb(2, 2, 118);
      }
      .success {
        color: rgb(3, 148, 3);
      }
    }
  }

  tbody tr:nth-child(odd) {
    background-color: rgb(227, 226, 226);
  }

  tbody tr:nth-child(even) {
    background-color: #ffffff;
  }
}

.table{
  width: 100%;
  margin-bottom: 20px;
}

@media (max-width: 1000px) {
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    padding: 0;
  }
  .info {
    width: 100%;
  }

  .info > .title {
    font-size: 24px;
  }

  .items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 200px;
    overflow-x: auto;
    width: 100%;
    max-height: none;
  }

  .subscribers > span {
    font-size: 20px;
  }

  .header {
    width: 100%;
    justify-content: space-between;
    gap: 5px;
    padding: 20px 10px;
    font-size: 18px;
  }

  .sorted {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }

  .header > input {
    width: 100%;
    min-width: 300px;
  }

  .data > .title {
    font-size: 18px;
  }
  .data > .email {
    font-size: 14px;
    color: #b6b6b6;
  }
  .subscribers {
    padding-left: 0;
  }

  .tabs > .input {
    max-width: 370px;
    width: 45%;
    min-width: 280px;
    margin-bottom: 16px;
  }
  .tabs > .buttons {
    gap: 10px;
  }
  .buttons > .item {
    min-width: 140px;
    overflow: hidden;
    padding: 25px;
    svg {
      display: none;
    }
  }

  .table{
    width: 100%;
    overflow-y: auto;
    max-width: 370px;
  }
}
