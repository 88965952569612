* {
  font-family: "Visuelt Pro";
  box-sizing: border-box;
}

html {
  font-size: 0.521vw;
}

body {
	background-color: #F7F8FA;
  overflow-x: hidden;
  width: 100vw;
  height: auto;

}

.font-link {
  color: aqua;
}

:root {
  --orange: #ff6e1d;
  --grey: #e6e7e9;
}

textarea {
  resize: none;
}

.grey {
  color: #b4b4b4;
  font-weight: 400;
  font-size: 14px;
}

.orange {
  color: #ff6e1d;
}

.div_nav {
  width: 25vw;
}

.div_main {
  width: 100vw;
  display: flex;
}

button {
  cursor: pointer;
}

.redirectLink {
  font-weight: 400;
  font-size: 16px;
}


.mb20 {
  margin-bottom: 20px;
}

.text-center {
  display: block;
  text-align: center;
}

@font-face {
  font-family: "Visuelt Pro";
  src: url(./fonts/VisueltPro-Regular.ttf);
}

