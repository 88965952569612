.wrong-back {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}


.wellDone {
  width: 90vw;
  max-width: 500px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  background-color: #f7f8fa; 
  border-radius: 10px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.11);
  gap: 20px;
}

.wellDone > h1 {
  font-size: 40px;
}
@media (max-width: 400px) {
	.wellDone > h1 {
		padding-left: 20px;
		text-align: left;
		font-size: 30px;
	}
}

@media (min-width: 401px) and (max-width: 600px) {
	.wellDone > h1 {
		padding-left: 20px;
		text-align: left;
		font-size: 36px;
	}
}
@media (min-width: 601px) and (max-width: 800px) {
	.wellDone > h1 {
		font-size: 40px;
	}
}

.wrong * {
  margin-top: 40px;
}

.wellDone button {
  margin-top: 30px;
	
  width: 90%;
	max-width: 600px;
}
