.input {
  width: 90%;
  min-width: 300px;
  height: 65px;
  background: #f7f8fa;
  border-radius: 10px;
  font-size: 16px;
  border: none;
  padding: 22px;
  box-sizing: border-box;
  font-family: "Visuelt Pro";
}

.input:focus-visible {
  outline: 1px solid var(--orange);
}

.option {
  font-size: 20px;
  height: 40px;
  font-family: "Visuelt Pro";
  cursor: pointer;
  background-color: #fff;
  color: rgb(56, 56, 56);
}
