.input {
  width: 97%;
	height: 65px;
  background: #f7f8fa;
  border-radius: 10px;
	font-size: 16px;
  border: none;
  padding: 22px;
	box-sizing: border-box;
}

.input:focus-visible {
  outline: 1px solid var(--orange);
}

.input[type="radio"] {
  appearance: none;
  display: inline-block;
  width: 20px;
  height: 20px;
  padding: 2px;
  background-clip: content-box;
  border: 2px solid #bbbbbb;
  background-color: #e7e6e7;
  border-radius: 50%;
}

.input[type="radio"]:checked {
  background-color: var(--orange);
}

.input[type="date"]:after {
	content: attr(placeholder) !important;
	color: #000000;
	margin-right: 0.5em;
}
.input[type="date"]:focus:after,
.input[type="date"]:valid:after {
	content: "";
}