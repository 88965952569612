.wrapper {
  margin-top: 20px;
  width: 75%;
  position: relative;
}

body {
  margin: 0;
  padding: 0;
  background-color: #f7f8fa;
}

header {
  box-sizing: border-box;
  padding: 23px 32px;
  width: 98%;
  max-width: 1546px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.14);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
	transition: width 0.5s;
  margin-bottom: 16px;
  /* height: 116px;  */
}

header > .input {
  max-width: 650px;
  width: 45%;
  min-width: 400px;
  margin-bottom: 16px;
}
header > .buttons {
  display: flex;
  width: 50%;
  gap: 20px;
  margin-bottom: 16px;
}
main{
  width: 98%;
  overflow:hidden;
}

.buttons > .item {
  /* padding: 17px 50px; */
  height: 65px;
  min-width: 190px;
  max-width: 215px;
  width: 45%;
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  background: #b6b6b6;
  overflow-x: auto;
}

.items {
  width: 100%;
  overflow-x: auto;
}

.right {
  margin-top: 10px;
  width: 98%;
  display: flex;
  justify-content: flex-end;
}

.item > svg {
  font-size: 24px;
}

.buttons > .itemActive {
  background:   #ff6e1d ;
}

@media (max-width: 1100px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
  }
  .items {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  header {
    width: 98%;
    justify-content: flex-start;
    gap: 5px;
    min-width: 400px;
    padding: 20px;
  }
	.right{
		width: 96%;
	}
}


@media (max-width: 600px) {
  header{
    min-width: 300px;
  }
  header > .buttons{
    width: 100%;
    
  }

  .buttons > .item{
    min-width: 100px;
  }

  .item > svg {
    font-size: 20px;
    width: 20px;
  }

  header > .input {
    width: 96%;
    min-width: 200px;
  }
}