body {
  margin: 0;
  padding: 0;
  background-color: #f7f8fa;
}

.wrapper {
  margin-left: 10px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 70vw;
  max-width: 1546px;
}

.header {
  display: flex;
  justify-content: space-between;
}

.header > p {
  font-size: 30px;
}
.header input {
  max-width: 650px;
  width: 30vw;
  min-width: 300px;
}

.main {
  margin-top: 20px;
  background: #fff;
  min-height: 792px;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
}

.heading {
  display: flex;
  position: relative;
  z-index: 0;
}

.heading > input {
  /* width: 90vw; */
  max-width: 1500px;
}

.heading > button {
  position: absolute;
  max-width: 260px;
  top: 6px;
  right: 60px;
  z-index: 0;
}

.items {
  margin-top: 150px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

@media (max-width: 1000px) {
  .wrapper {
    display: flex;
    justify-content: center;
    width: 100vw;
    margin-left: 0px;
    margin-top: 20px;
  }
  .main {
    width: 90vw;
  }
  .heading > button {
    position: absolute;
    max-width: 260px;
    top: 100px;
    right: 60px;
    z-index: 0;
  }
}
