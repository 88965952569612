.wrapper {
  display: flex;
  justify-content: center;
  width: 100vw;
  padding: 60px 0;
}

.content {
  width: 80vw;
  max-width: 1000px;
}

.heading > button {
  max-width: 300px;
  background: transparent;
}


.rule_button> svg {
  background-color: transparent;
  font-size: 25px;
}

.main {
  margin-top: 40px;
  font-size: 20px;
}

.main > h2 {
  font-size: 25px;
}

.header {
  display: flex;
  justify-content: space-between;
	align-items: center;
}

.remove {
	cursor: pointer;
}
.remove svg {
	font-size: 25px;
	transition: color 0.5s;
}
.remove svg:hover{
	color: #ff6e1d;
}

.rule {
  margin-top: 10px;
}

.rule h3 {
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
}
.rule > p {
  line-height: 30px;
  margin-top: 10px;
  color: #1f1f1f;
	word-wrap: break-word;
}

.buttonFixed {
  position: fixed;
  top: 85svh;
  right: 150px;
}
.buttonFixed svg {
  font-size: 30px;
}


li{
  list-style-type: none;
  margin-left: 10px;
  margin-bottom: 5px;
  text-indent: 10px;
}

@media (max-width: 1000px) {
	.content{
    width: 100%;
    padding: 16px;
  }
  li{
    margin-left: 0;
  }
}