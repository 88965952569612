.adv {
  padding: 20px;
  width: 400px;
  border: 1px solid black;
  background-color: white;
  border-radius: 10px;
}

.main {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.values {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.img {
  width: 120px;
  height: 120px;
  border-radius: 10px;
  background-color: var(--grey);
}

.line {
  width: 100%;
  height: 10px;
  border-radius: 20px;
  background-color: var(--grey);
  margin: 10px 0px 30px 0px;
}

.line .completed {
  height: 100%;
  background-color: var(--orange);
  border-radius: 20px;
}

.firstNum {
  color: var(--orange);
}

.secondNum {
  color: #b4b4b4;
}

.firstBtn {
  color: #6dbc2d;
  border: 1px solid #6dbc2d;
  border-radius: 10px;
  width: 150px;
  height: 40px;
}

.secondBtn {
  color: #adadad;
  border: none;
  border-radius: 10px;
  width: 150px;
  height: 40px;
}

span {
  font-weight: bold;
}
