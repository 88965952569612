.genre {
	padding: 4px 10px;
	display: inline-block;
  border-radius: 20px;
  border: 1px solid #ffa978;
  background: #fff;
	color: #ffa978;
	font-size: 14px;
	/* max-width: 75px; */
}
