* {
  margin: 0;
  padding: 0;
}
.genre__back {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(247, 248, 250, 0.8);
  z-index: 10007;
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 40px;
  color: #474747;
}

.div_genre {
  min-width: 375px;
  width: 100vw;
  max-width: 770px;
  margin: 0% auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 100010;
  background: #ffffff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.div_inp {
  min-width: 375px;
  width: 100vw;
  max-width: 770px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.search {
  width: 100vw;
  max-width: 770px;
  display: flex;
  justify-content: center;
}

.div_inp input {
  min-width: 375px;
  padding: 10px 20px;
  font-size: 20px;
  width: 90vw;
  max-width: 710px;
  box-sizing: border-box;
}

.div_button {
  height: 100px;
  width: 100vw;
  max-width: 770px;
  display: flex;
  justify-content: center;
}
.div_button button {
  margin-top: 20px;
  font-size: 20px;
  width: 90vw;
  max-width: 710px;
}
.div_genre h1 {
  font-size: 30px;
  font-weight: 500;
}
.div_genre input {
  height: 65px;
  background: #f7f8fa;
  border-radius: 10px;
  border: none;
}
.genre_div {
  width: 90%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.but_genre {
  display: flex;
  width: auto;
  height: 45px;
  border: solid 1px #e6e7e9;
  border-radius: 30px;
  background: #ffffff;
  color: #b4b4b4;
  padding: 10px;
  align-items: center;
}

.but_genre > svg {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-left: 5px;
  display: none;
}

button.active {
  border: solid 1px #ff6e1d;
  color: #ff6e1d;
}

button.active > svg{
  display: block;
}

.genre_but {
  width: 65rem;
  height: 65px;
  background: #e6e7e9;
  font-size: 1.6rem;
  color: #ffffff;
  border-radius: 10px;
  border: none;
}

.genre_but.orange {
  background-color: #ff6e1d;
}

.search {
  position: relative;
}

.search > .searchLabel {
  cursor: text;
  font-size: 16px;
  color: #b4b4b4;
  display: flex;
  align-items: center;
  gap: 7px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 700px){
  .genre_div{
    height: 300px;
    overflow-y: scroll;
  }

  .div_genre h1 {
    font-size: 20px;
  }
}
