.wrapper {
    margin-top: 20px;
    display: flex;
    justify-content: center;
		align-items: flex-start;
    flex-wrap: wrap;
}


@media (max-width: 1000px) {
    .wrapper {
        width: 100vw;
    }
}

body {
    margin: 0;
    padding: 0;
    background-color: #f7f8fa;
}

.wrapperAuthor {
    margin-top: 15px;
    color: #000;
    width: 70vw;
    display: flex;
    justify-content: space-between;
    max-width: 1546px;
    border-radius: 10px;
    background: #fff;
    padding: 17px 20px;
    transition: box-shadow 0.5s;
    max-height: 100px;
}

.wrapperAuthor:hover {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.11);
    color: #000;
}

.wrapperAuthor > .first {
    gap: 20px;
    min-width: 150px;
    width: 10vw;
    display: flex;
    align-items: center;
    font-size: 20px;
    cursor: pointer;
}

.wrapperAuthor button {
    padding: 10px 20px;
    background-color: #FF6E1D;
    color: #fff;
    font-size: 18px;
    border: none;
    border-radius: 10px;
}

.items {
    min-height: 1000px;
}

.right {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}