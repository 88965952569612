.wrapperBook {
  margin-top: 10px;
  background: #fff;
  min-width: 1340px;
  display: flex;
  height: 180px;
  justify-content: space-between;
  padding: 17px 13px;
  border-radius: 10px;
  transition: box-shadow 0.5s;
  overflow:hidden;
  box-sizing: border-box;
  gap: 10px;
}



.wrapperBook:hover {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.11);
}
.wrapperBook > h3 {
  font-size: 24px;
}

.count {
  width: 2%;
  font-size: 14px;
  margin-top: 15px;
}

.first {
  display: flex;
  width: 100px;
}

.img {
  width: 100px;
  height: 140px;
  background-size: cover;
  background-position: center;
}

.authorName {
  width: 10%;
  line-height: 25px;
  font-size: 16px;
  margin-top: 15px;
}

.wrapperBook > p {
  font-size: 20px;
}
.name{
  width: 12%;
  margin-top: 15px;
}
.title {
  line-height: normal;
  font-weight: 600;
  font-size: 16px;
}

.items {
  width: 32%;
  display: flex;
  gap: 50px;
}

.items > div{
  width: 20%;
  font-size: 18px;
  font-weight: 600;
  margin-top: 15px;
}



.item h3 {
  font-size: 24px;
}

.wrapperBook > svg {
  color: #a6a6a6;
  font-size: 25px;
  cursor: pointer;
  transition: color 0.5s;
}

.wrapperBook > svg:hover {
  color: #ff6e1d;
}
.genres {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  margin-top: 15px;
}
.flex {
  display: flex;
  gap: 10px;
  width: 32%;
}

.restriction {
  display: flex;
  width: 26px;
  height: 26px;
  padding: 1px;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: #e6e7e9;
  color: #b4b4b4;
  font-size: 12px;
  line-height: normal;
}
.div_status {
  width: 12%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.status {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  font-size: 18px;
  color: #888;
  padding-left: 20px;
}

.status:first-child {
  margin-top: 5px;
}

.status > p {
  width: 160px;
}
.status > svg {
  font-size: 25px;
}

.activeStatus {
  color: #23aa69;
}

.end {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 45px;
  gap: 20px;
}

.end > svg {
  font-size: 25px;
  cursor: pointer;
  transition: color 0.5s;
}

.end > svg:hover {
  color: #ff6e1d;
}


.reject {
  color: #ff0000;
}



.confirm {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  top: 0;
  left: 0;
}



.confirm_content {
  width: 100%;
  position: relative;
  max-width: 500px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  background-color: #f7f8fa;
  border-radius: 10px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.11);
  padding: 30px;
  text-align: center;
}

.confirm_content > P {
  font-size: 20px;
  font-weight: bold;
  line-height: 22px;
}

.confirm_content > svg{
  position: absolute;
  top: 20px;
  right: 20px;
}

.confirm_content > div {
  width: 90%;
  display: flex;
  gap:24px;
  justify-content: center;
}

.confirm_button, .confirm_button_cancel {
  margin-top: 20px;
  width: 45%;
  max-width: 130px;
  height: 46px;
  background: var(--orange);
  border-radius: 10px;
  border: none;
  color: #fff;
  font-size: 16px;
}

.confirm_button_cancel{
  background: var(--grey);
  color: #adadad;
}

.status {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: flex-start;
  font-size: 18px;
  color: #888;
  padding-left: 20px;
  flex-direction: column;

  div{
    display: flex;
  }

  .statusIcon{
    display: flex;
    align-items: center;

    p {
      display: block;
      width: 160px;
      padding-top: 2px;
    }
  }

  .data{
    color: #888;
    font-size: 14px;
    display: block;
  }
}

.activeStatus {
  color: #23aa69;
}

.reject {
  color: #ff0000;
}


@media (max-width: 1000px) {
  .confirm_content > P {
    font-size: 16px;
    font-weight: bold;
  }
}