.login__container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100vw;
  background-color: #ffff;
}

.login {
  width: 90vw;
  max-width: 600px;
}

.login > h1 {
  font-size: 60px;
}

@media (max-width: 600px) {
  .login > h1 {
    font-size: 36px;
  }
}

@media (min-width: 601px) and (max-width: 800px) {
  .login > h1 {
    font-size: 40px;
  }
}
@media (min-width: 801px) and (max-width: 1150px) {
  .login > h1 {
    font-size: 45px;
  }
}

.rout {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

a {
  text-decoration: none;
  color: #ff6e1d;
}

a:hover{
  font-size: 18px;
  font-weight: 600;
}

.radio {
  display: flex;
  align-items: center;
  height: 40px;
}

.custom-label {
  font-size: 18px;
  font-weight: 400;
  color: #b4b4b4;
}

.data-processing-link{
  font-family: var(--font-family);
font-weight: 400;
font-size: 14px;
color: #b4b4b4;
text-decoration: none;
}

.data-processing-link:hover{
  color:  #ff6e1d;
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
}

.login * {
  margin-top: 20px;
}

.recap {
  width: 100px;
}

.rout {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.checkbox {
  display: flex;
	align-items: center;
  gap: 10px;
  color: #b4b4b4;
	font-size: 18px;
}
.checkbox p {
	height: 0px;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 20px;
  user-select: none;
}

.checkmark {
  position: relative;
  top: 0;
  left: 0;
  height: 1.3em;
  width: 1.3em;
  border: 2px solid #414141;
  transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}

.container input:checked ~ .checkmark {
  background: #ff6e1d;
  border-color: #ff6e1d;
  filter: drop-shadow(5px 5px);
}

.checkmark:after {
  content: "";
  position: absolute;
}

.container .checkmark:after {
  inset: 0;
  margin: auto;
  width: 0.5em;
  height: 0.5em;
  background-color: #414141;
  border-width: 0 0.15em 0.15em 0;
}

.container input:checked ~ .checkmark:after {
  width: 0.25em;
  height: 0.5em;
  border: solid #414141;
  border-width: 0 0.15em 0.15em 0;
  border-color: white;
  background-color: transparent;
  transform: rotate(45deg);
}

.password{
  display: block;
  position: relative;
}

.password > svg{
  position: absolute;
  top: 25px;
  right: 30px;
  color: #b4b4b4;
}