.container{
    display: flex;
    flex-direction: column;
    width: 94%;
    margin: 0 auto;
    padding: 16px;
    gap: 10px;
    box-sizing: border-box;
}
h1{
    font-size: 28px;
}
h2{
    align-self: center;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    text-transform:uppercase;
}

p{
    text-indent: 10px;
}

p>p{
    margin-left: 10px;
}