.wrapperSort{
	margin-top: 20px;
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	align-items: center;
	font-size: 20px;
	width: 100%;
}

.wrapperSort > p {
	color: #888; 
	cursor: pointer;
}

.wrapperSort > svg {
	color: #FF6E1D;
}

.wrapperSort > .active {
	color: #FF6E1D;
}
.desc {
	transform: rotate(180deg);
}