.close {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 40px;
  color: #474747;
}

.createBook_main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  max-width: 770px;
  min-height: 800px;
  background: #ffffff;
  margin: 0% auto;
  padding: 30px;
  border-radius: 10px;
  min-width: 355px;
}
.createBook_main h1 {
  font-size: 30px;
}

.createBook-form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 90%;
}

.createBook_form {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5rem;
  min-width: 350px;
}
.divInp_file {
  background: #e6e7e9;
  border-radius: 10px;
  border-radius: 10px;
  overflow: hidden;
  min-width: 300px;
  max-width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.divInp_file > img {
  height: 100%;

}
.divInp_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.divInp_form > input {
  padding: 10px 20px;
}

.noneImg {
  height: 300px;
  font-size: 16px;
  color: #adadad;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
}

svg {
  width: 300px;
}

.noneImg > p {
  width: 96px;
  text-align: center;
}

.divForm_inp {
  width: 40rem;
  min-width: 300px;
  height: 65px;
  border-radius: 10px;
  border: none;
  background: #f7f8fa;
  font-size: 16px;
}
.inp_all {
  height: 90px;
  padding: 22px 12px;
  box-sizing: border-box;
  font-size: 16px;
}
.div_limitation {
  width: 20vw;
  min-width: 300px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  font-size: 20px;
}
.div_limitation input {
  margin-top: 15px;
  /* width: 54px; */
  /* height: 54px; */
}
.div_limitation span {
  margin-top: 20px;
  font-size: 15px;
  font-weight: normal;
}
.div_genres {
  position: relative;
  margin-top: 40px;
  padding-left: 20px;
  width: 95%;
  min-width: 300px;
  min-height: 80px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;

}
.div_genres button {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  height: 45px;
  border: none;
  font-size: 16px;
  border-radius: 30px;
  border: solid 1px #ff6e1d;
  background: #ffffff;
  color: #ff6e1d;
}

.div_genres button > svg {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-left: 5px;
}

.delete_all_genres {
  position: absolute;
  top: 0;
  right: 0;
}

.div_formatBook {
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  background-color: #f7f8fa;
  overflow-y: scroll;
  width: 95%;
  max-width: 710px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.div_formatBook input {
  border: none;
  height: 65px;
}
.inp {
  display: none;
  cursor: pointer;
}
.addChap {
  margin-top: 20px;
  height: 20px;
}

.center {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100vw;
  max-width: 770px;
}

.addChapter {
  width: 70vw;
  max-width: 710px;
  min-width: 300px;
  height: 75px;
  border: none;
  border: solid 1px #ff6e1d;
  color: #ff6e1d;
  background: #ffffff;
  border-radius: 10px;
  font-size: 16px;
  margin-top: 10px;
}
.div_save {
  width: 70vw;
  max-width: 710px;
  min-width: 300px;
  height: 95px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.div_save button {
  max-width: 350px;
}
.bookRules {
  width: 350px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-self: flex-start;
  gap: 4rem;
}
input[type="checkbox"] {
  width: 24px;
  height: 24px;
  border: solid 1px #b4b4b4;
  appearance: none;
  border-radius: 50%;
  padding: 2px;
  /* box-sizing: border-box; */
  background-clip: content-box;
}

input[type="checkbox"]:checked {
  background-color: #ff6e1d;
}

.bookRules a {
  color: #b4b4b4;
  font-size: 14px;
  font-weight: 400;
}

.bookRules a:hover{
  color:  #ff6e1d;
  font-weight: 600;
  text-decoration: underline;
  font-size: 16px;
}


@media (max-width: 600px){
  .div_save{
    flex-direction: column;
  }
  .createBook_form {
    width: 96%;
  }
}