
.main_container{
  width: 95%;
}

.container {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  gap: 10px;
	height: 65px; 
	min-width: 500px;
  cursor: grab;
  margin: 5px 0;
}

.number{
  width: 4%;
  background: transparent;
  pointer-events: none;
}

.chapter_information{
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;

  >div{
    width: 30%;
  }
}

.chapter_input{
  border-radius: 10px;
	height: 65px; 
	box-sizing: border-box;
  padding: 15px 30px;
  color: #ff6e1d;
  min-width: 190px;
  max-width: 350px;
  width: 65%;
  background: #ffceb2;
  font-size: 14px;
}
.chapter_input::placeholder{
  font-size: 14px;
}

.file_div{
  max-width: 190px;
  width: 30%;
}
.unload{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.unload > p{
  font-size: 14px;
  margin-right: 4px;
  max-width: 160px;
  overflow: hidden;
}

.unload > svg{
  font-size: 16px;
}
.file_input{
  display: none;
}

.file_p{
  display: block;
  font-weight: 400;
  font-size: 14px;
  color: #ff6e1d;
  min-width: 180px;
  overflow: hidden;
}

.confirm {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(255, 255, 255, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  top: 0;
  left: 0;
}



.confirm_content {
  width: 90vw;
  position: relative;
  max-width: 500px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  background-color: #f7f8fa;
  border-radius: 10px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.11);
  padding: 30px;
  text-align: center;
}

.confirm_content > P {
  font-size: 20px;
  font-weight: bold;
}

.confirm_content > svg{
  position: absolute;
  top: 20px;
  right: 20px;
}

.confirm_content > div {
  width: 90%;
  display: flex;
  gap:24px;
  justify-content: center;
}

.confirm_button, .confirm_button_cancel {
  margin-top: 20px;
  width: 45%;
  max-width: 130px;
  height: 46px;
  background: var(--orange);
  border-radius: 10px;
  border: none;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}

.confirm_button_cancel{
  background: var(--grey);
  color: #adadad;
}




@media (max-width: 1000px) {
	.tr {
		min-width: 700px;
	}

  .container{
    min-width: 350px;
  }
  .file_p{
    font-size: 12px;
  }
}
.confirm_content > P {
  font-size: 16px;
  font-weight: bold;
}

.txt {
  color: #ff6e1d;
  border-radius: 10px;
}

.txt > p {
  color: #ff6e1d;
}

svg {
  cursor: pointer;
  font-size: 20px;
  max-width: 20px;
}