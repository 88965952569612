.forgot__container {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	width: 100vw;
	background-color: #ffff;

}

.forgot {
  width: 90vw;
	max-width: 600px;
}


.forgot > h1 {
  font-size: 45px;
}

@media (max-width: 600px) {
	.forgot > h1 {
		font-size: 36px;
	}
}

@media (min-width: 601px) and (max-width: 800px) {

	.forgot > h1 {
		font-size: 40px;
	}
}
@media (min-width: 801px) and (max-width: 1150px) {

	.forgot > h1 {
		font-size: 45px;
	}
}

.forgot > h1 {
	text-align: center;
}
 .forgot__label {
	font-size: 14px;
	line-height: 20px;
	margin: 16px 0;
	display: inline;
}

.forgot > .reset {
	width: 100%;
	margin: 0 auto;
	margin-top: 60px;
}

.forgot .route {
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.reset * {
	margin: 10px 0;

}

.reset > span{
	display: block;
	color: red;
	font-weight: 300;
}