.modal_container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background:  rgba(165, 161, 161, 0.281);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index:99999;
  }
  
  
  .modal_content {
    width: 90vw;
    max-width: 500px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    background-color: #f7f8fa; 
    border-radius: 10px;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.11);
    gap: 20px;
    padding: 0 24px;
  }
  
  .modal_content > p {
    font-size: 25px;
    text-align: center;
  }

  
  .modal_content > button {
    margin-top: 30px;
    width: 70%;
    max-width: 600px;
  }
  