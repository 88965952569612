

.mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(165, 161, 161, 0.281);
  z-index: 6;
  overflow-y: scroll;

}

.createBook_admin {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  max-width: 770px;
  min-height: 800px;
  background: #ffffff;
  margin: 0% auto;
  padding: 30px;
  border-radius: 10px;
  min-width: 355px;
  height: auto;
  box-sizing: border-box;
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 40px;
  color: #474747;
  width: 50px;
  height: 50px;
}

.close > svg {
  width: 50px;
}

.createBook_main {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 80%;
  max-width: 830px;
  min-height: 800px;
  background: #ffffff;
  margin: 40px auto;
  padding: 30px;
  border-radius: 10px;
  min-width: 355px;
  opacity: 1;
  height: auto;
}
.createBook_main h1 {
  font-size: 30px;
}

.createBook-form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 90%;
}

.createBook_form {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.divInp_file {
  background: #e6e7e9;
  border-radius: 10px;
  border-radius: 10px;
  overflow: hidden;
  min-width: 300px;
  max-width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.divInp_file > img {
  height: 100%;
}
.divInp_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.divInp_form > input {
  padding: 10px 20px;
  width: 100%;
}

.noneImg {
  height: 300px;
  font-size: 16px;
  color: #adadad;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
}

svg {
  width: 300px;
}

.noneImg > p {
  width: 96px;
  text-align: center;
}

.divForm_inp {
  width: 40rem;
  min-width: 300px;
  height: 65px;
  border-radius: 10px;
  border: none;
  background: #f7f8fa;
  font-size: 16px;
}
.inp_all {
  width: 100%;
  height: 90px;
  padding: 22px 12px;
  box-sizing: border-box;
  font-size: 16px;
}
.div_limitation {
  width: 20vw;
  min-width: 300px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  font-size: 20px;
}
.div_limitation input {
  margin-top: 15px;
  /* width: 54px; */
  /* height: 54px; */
}
.div_limitation span {
  margin-top: 20px;
  font-size: 15px;
  font-weight: normal;
}
.div_genres {
  position: relative;
  margin-top: 40px;
  padding-left: 20px;
  width: 95%;
  min-width: 300px;
  min-height: 80px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
}
.div_genres button {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  height: 45px;
  border: none;
  font-size: 16px;
  border-radius: 30px;
  border: solid 1px #ff6e1d;
  background: #ffffff;
  color: #ff6e1d;
}

.div_genres button > svg {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-left: 5px;
}

.delete_all_genres {
  position: absolute;
  top: 0;
  right: 0;
}

.div_formatBook {
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  background-color: #f7f8fa;
  overflow-x: auto;
  width: 90%;
  max-width: 710px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.div_formatBook input {
  border: none;
  height: 65px;
}
.inp {
  display: none;
  cursor: pointer;
}
.addChap {
  margin-top: 20px;
  height: 20px;
}

.center {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 770px;
  margin-bottom: 20px;
}

.addChapter {
  width: 70vw;
  max-width: 710px;
  min-width: 300px;
  height: 75px;
  border: none;
  border: solid 1px #ff6e1d;
  color: #ff6e1d;
  background: #ffffff;
  border-radius: 10px;
  font-size: 16px;
  margin-top: 10px;
}
.div_save {
  width: 70vw;
  max-width: 710px;
  min-width: 300px;
  height: 95px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}
.div_save button {
  max-width: 100%
}

.div_edit {
  width: 70vw;
  max-width: 710px;
  min-width: 300px;
  height: 95px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}
.bookRules {
  width: 350px;
  height: 80px;
  display: flex;
  align-items: center;
  gap: 4rem;
}
input[type="checkbox"] {
  width: 24px;
  height: 24px;
  border: solid 1px #b4b4b4;
  appearance: none;
  border-radius: 50%;
  padding: 2px;
  /* box-sizing: border-box; */
  background-clip: content-box;
}

input[type="checkbox"]:checked {
  background-color: #ff6e1d;
}
.bookRules p {
  font-size: 14px;
}
.bookRules span {
  color: #ff6e1d;
}

.confirm {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  top: 0;
  left: 0;
}



.confirm_content {
  width: 100%;
  position: relative;
  max-width: 500px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  background-color: #f7f8fa;
  border-radius: 10px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.11);
  padding: 30px;
  text-align: center;
}

.confirm_content > P {
  font-size: 20px;
  font-weight: bold;
  line-height: 22px;
}

.confirm_content > svg{
  position: absolute;
  top: 20px;
  right: 20px;
}

.confirm_content > div {
  width: 90%;
  display: flex;
  gap:24px;
  justify-content: center;
}

.confirm_button, .confirm_button_cancel {
  margin-top: 20px;
  width: 45%;
  max-width: 130px;
  height: 46px;
  background: var(--orange);
  border-radius: 10px;
  border: none;
  color: #fff;
  font-size: 16px;
}

.confirm_button_cancel{
  background: var(--grey);
  color: #adadad;
}

@media (max-width: 700px){
  .createBook_main{
    width: 100%;
    padding: 25px;
  }
}