.edit__container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: auto;
	width: 50%;
	background-color: #ffff;
	padding: 16px;
	box-sizing: border-box;

}

.edit {
  width: 90%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
}


.edit > h1 {
  font-size: 18px;
}

@media (max-width: 600px) {
	.edit > h1 {
		font-size: 36px;
	}
}

@media (min-width: 601px) and (max-width: 800px) {

	.edit > h1 {
		font-size: 40px;
	}
}
@media (min-width: 801px) and (max-width: 1150px) {

	.edit > h1 {
		font-size: 45px;
	}
}

.edit > h1 {
	text-align: center;
}
 .edit__label {
	display: block;
	font-size: 14px;
	line-height: 20px;
	margin-top:  16px;
	display: inline;
	color: #b4b4b4;
	font-weight: 400;
	font-size: 14px;
}

.edit > .reset {
	width: 100%;
	margin: 0 auto;
}

.edit {
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.reset * {
	margin-top: 10px;
	margin-bottom: 10px;
}
