.heading {
  display: flex;
  align-items: center;
  font-size: 25px;
  gap: 10px;
  color: #000;
}

.heading p {
  font-size: 25px;
  cursor: pointer;
  color: #FF6E1D;
  text-decoration: underline;
}
.heading svg {
	color: #FF6E1D;
}

.items {
  margin-top: 10px;
  position: absolute;
  background-color: #fff;
	box-shadow: 0 0 20px #d1d1d1;
	width: 50%;
	min-width: 250px;
	/* margin-left: 100px; */
  padding: 10px 0;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

.option {
  width: 90%;
  border-radius: 10px;
	min-width: 250px;
  color: #FF6E1D;
  background-color: transparent;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.5s;
}
.option:hover {
  background-color: #FF6E1D;
	color: #fff;
}
.active {
  background-color: #FF6E1D;
	color: #fff;
}

.desc {
	transform: rotate(180deg);
}

@media (max-width: 1000px){
  .heading p{
    font-size: 20px;
  }
}