.genre {
  color: #b4b4b4;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 30px;
  border: 1px solid #b4b4b4;
  background: #fff;
	display: flex;
	align-items: center;
	gap: 10px;
}

.genre > svg{
	font-size: 20px;
	display: none;
}

.genre:hover{
	color: #FF6E1D;
	border: 1px solid #FF6E1D;
	cursor: pointer;
}

.genre:hover > svg {
	display: block;
}